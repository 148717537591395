import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Box from '../components/Box';
import Logo from '../components/Logo';

const Wrap = styled.div`
  background-color: ${(props) => props.theme.colours.white};
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 0 40px;
  margin-bottom: 168px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colours.black};
  h1 {
    padding: 0;
    font-size: 42px;
  }
  h4 {
    margin-top: 30px;
    font-size: 16px;
    text-align: center;
  }
  img {
    margin: 40px 0;
    width: 100%;
    max-width: 480px;
  }
  a.button {
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    background-color: ${(props) => props.theme.colours.blue};
    color: ${(props) => props.theme.colours.white};
    border-radius: 3px;
    border: none;
  }
`;

const NotFoundPage = () => (
  <Wrap>
    <Box p={30} bg="indigo">
      <Link to="/">
        <Logo />
      </Link>
    </Box>
    <Content>
      <h1>404</h1>
      <h4>The page you are looking for cannot be found.</h4>
      <img src="https://media.giphy.com/media/mXwyHomA2kQbQHuh2R/giphy.gif" alt="Lost Rock" />
      <Link to="/" className="button">
        Return to home
      </Link>
    </Content>
  </Wrap>
);

export default NotFoundPage;
